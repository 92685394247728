import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function MyNavbar() {
    return (
        <>
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img className='logogo' src='images/logo4.png' alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="navvv" id="basic-navbar-nav">
                        <Nav>
                        <Nav.Link as={HashLink} className='navtext' to="/">Introducing our Beta Programs :  Attend our Virtual Open House </Nav.Link>
                            {/* <Nav.Link as={HashLink} className='navtext' to="/#link">About</Nav.Link>
                            <Nav.Link as={HashLink} className='navtext' to="/#Services">Services</Nav.Link>
                            <Nav.Link as={Link} className='navtext' to="/CaseStudy">Case Studies</Nav.Link>
                            <Nav.Link as={HashLink} className='navtext' to="/#Prices">Beta Program</Nav.Link>
                            <Nav.Link as={HashLink} className='navtext' to="/#Contact">Contact Us</Nav.Link>  */}
                            <a className='talktous' href="https://calendly.com/r-dredhartclearskymarketing/overview-of-construction-beta-program" target="_blank" rel="noopener noreferrer">Talk to Us</a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}
