import React from "react";
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './MyFooter.css';
import 'animate.css';

export default function MyFooter() {
    return (
        <>
            <div className="foot" id="Contact">
                <div className="foot2">
                    <div className="frow1">
                        <div className="contft">
                            <img className="ftlogo" src="images/footlogo.png" alt="Footer Logo" />
                        </div>
                    </div>
                    <div className="frow2">
                    <h4>Contact Us</h4>
                    <div className="connect">
                                <p><strong>Email:</strong> r.dredhart@clearskysoftware.net</p>
                                <p><strong>Phone:</strong> 1-705-274-9564</p>
                            </div>
                        {/* <h4>Quick Links</h4>
                        <Nav className="footnav">
                            <Nav.Link as={HashLink} className='navtext11' to="/">Home</Nav.Link>
                            <Nav.Link as={HashLink} className='navtext11' to="/#link">About</Nav.Link>
                            <Nav.Link as={HashLink} className='navtext11' to="/#Services">Services</Nav.Link>
                            <Nav.Link as={Link} className='navtext11' to="/CaseStudy">Case Studies</Nav.Link>
                            <Nav.Link as={HashLink} className='navtext11' to="/#Prices">Beta Program</Nav.Link>
                            <Nav.Link as={HashLink} className='navtext11' to="/#Contact">Contact Us</Nav.Link>
                        </Nav> */}
                    </div>
                    <div className="frow3">
                        <div className="footions">

                            <div className="social-icons">
                                <a href="https://www.facebook.com/ClearSkySoftwareSolutions/" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/icons (1).png" alt="Facebook" height={28} />
                                </a>
                                <a href="https://www.linkedin.com/company/clearsky-software/" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/icons (2).png" alt="LinkedIn" height={30} />
                                </a>
                                <a href="https://www.youtube.com/@ClearSkySoftwareSolutions" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/icons (3).png" alt="YouTube" height={32} />
                                </a>
                            </div>
                            <div className="btfoot">
                                <a className='talktousfoot' href="https://calendly.com/r-dredhartclearskymarketing/overview-of-construction-beta-program" target="_blank" rel="noopener noreferrer">Talk to Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="foot3">
                <p>© ClearSkySoftware. All Rights Reserved 2024.</p>
            </div>
        </>
    );
}
